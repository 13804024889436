@use '@/styles/utils/mixins.scss' as *;

.customRadio {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  .customRadioLabelBox {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
  }
  .customRadioLabel {
    font-size: 0.875rem;
    font-weight: 500;
    color: $neutral07;
    margin-bottom: 0;
    line-height: 1;
  }
  .customRadioDescription {
    font-size: 0.75rem;
    font-weight: 400;
    color: $neutral07;
  }
}
